import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { Amplify } from "aws-amplify";

// import "./wdyr";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import reportHandler from "./reportHandler";
import setupAnalytics from "./setupAnalytics";
import { store } from "./store";
import { sentryDsn } from "./constants/sentryConfig";
import { AWS_CONFIG } from "./constants/onmo";
import { OnmoStorage } from "./models/onmoStorage";
import { FallbackError } from "./components/common/FallbackError";
import { initKeycloak } from "./clients/keycloak";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AWS_CONFIG.aws_user_pools_id,
      userPoolClientId: AWS_CONFIG.aws_user_pools_web_client_id,
      identityPoolId: AWS_CONFIG.aws_cognito_identity_pool_id,
      loginWith: {
        oauth: {
          domain: AWS_CONFIG.oauth.domain,
          scopes: AWS_CONFIG.oauth.scope,
          redirectSignIn: [AWS_CONFIG.oauth.redirectSignIn],
          redirectSignOut: [AWS_CONFIG.oauth.redirectSignOut],
          responseType: "code",
        },
      },
    },
  },
  Storage: {
    S3: {
      bucket: AWS_CONFIG.Storage.AWSS3.bucket,
      region: AWS_CONFIG.Storage.AWSS3.region,
    },
  },
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  OnmoStorage.getAmplifyStorage(),
);

// setup GA4
setupAnalytics();

// setup WebVitalss
reportWebVitals(reportHandler);

// setup Sentry
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.REACT_APP_ENABLED_THEME || "dev",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development",
});

window.global ||= window;

// init keycloak
initKeycloak(store.dispatch);

const root = document.getElementById("root") as HTMLElement;
createRoot(root).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: () => {
    window.postMessage("app_updated");
  },
});
