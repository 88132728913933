import React, {
  FC,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "classnames";
import { useStyles } from "./Avatar.style";
import { Image } from "../../core/Image/Image";
import CrownIcon from "../../common/ResultAnimation/assets/won_battle_1x.webp";
import CrownIcon2x from "../../common/ResultAnimation/assets/won_battle_2x.webp";
import CrownIcon3x from "../../common/ResultAnimation/assets/won_battle_3x.webp";
import { BadgeGame } from "../../core/BadgeGame/BadgeGame";
import { BorderRadius } from "../../../constants/borderRadius";
import { ProgressBar } from "../../core/ProgressBar";
import { RankColor, UserStatus } from "../../../constants/user";
import { ILevel } from "../../../types/user";
import { StaticImage } from "../../common/StaticImage";
import { isString } from "lodash-es";
import { NB_SECOND_SHOW_PROGRESS } from "../../../constants/endscreen";
import { isLevelUp } from "../../../models/userFactory/userFactory";
import { findGameImage, getHighResGameImage } from "../../../models/game/game";
import { useAppSelector } from "../../../hooks";
import {
  AvatarHeightMapping,
  AvatarSizeT,
  AvatarWidthMapping,
  defaultGameSrcSet,
  defaultProfileSrcSet,
  getSizeBadge,
  getSizeProgress,
} from "./fixture";

interface IAvatarProps {
  url?: string;
  size: AvatarSizeT;
  showProgress?: boolean;
  onClickBadge?: () => void;
  userRank?: ILevel;
  status?: UserStatus | React.ReactNode;
  crownIcon?: boolean;
  gameId?: string;
  isRankAnimation?: boolean;
  nbGem?: number;
  hideRank?: boolean;
  nextRank?: ILevel;
  isHideRankId?: boolean;
  rootClasses?: string;
}

export const Avatar: FC<IAvatarProps & ImgHTMLAttributes<HTMLImageElement>> = ({
  alt,
  url,
  size,
  userRank,
  showProgress,
  onClickBadge,
  status,
  crownIcon,
  gameId,
  isRankAnimation,
  nbGem,
  hideRank,
  nextRank,
  isHideRankId,
  rootClasses,
  className,
  ...otherProps
}) => {
  const classes = useStyles(nbGem ?? 0);
  const { listGame } = useAppSelector((state) => state.game);
  const [srcSet, setSrcSet] = useState<string | undefined>(url);
  const [isFinishedAnimation, setFinishedAnimation] = useState(false);
  const isRankUp = !!userRank && isLevelUp(userRank, nbGem || 0);
  const isSmallSize = ["s", "m", "l"].includes(size);

  const avatarWidth = AvatarWidthMapping[size];
  const avatarHeight = !gameId
    ? AvatarWidthMapping[size]
    : AvatarHeightMapping[size];

  const avatarRadius = useMemo(() => {
    if (!gameId) return "50%";
    if (isSmallSize) {
      return BorderRadius.sm;
    } else return BorderRadius.md;
  }, [gameId, isSmallSize]);

  const onError = useCallback(() => {
    if ((!isString(url) && !gameId) || !listGame) return;
    setSrcSet(!gameId ? defaultProfileSrcSet : defaultGameSrcSet);
  }, [url, gameId, listGame]);

  const getUserAvatar = () => {
    if (gameId) {
      const currentGameImage = findGameImage(listGame, {
        gameId,
        type: isSmallSize ? "small" : "big",
      });
      return getHighResGameImage(currentGameImage).png || "";
    } else {
      if (url && url.includes("http")) {
        return url;
      }
      return defaultProfileSrcSet;
    }
  };

  useEffect(() => {
    if (!isRankAnimation || !isRankUp) return;
    const timeoutProgress = setTimeout(() => {
      setFinishedAnimation(true);
    }, NB_SECOND_SHOW_PROGRESS);
    return () => {
      clearTimeout(timeoutProgress);
    };
  }, [isRankAnimation, isRankUp]);

  return (
    <div className={clsx(classes.roundedAvatar, rootClasses)}>
      <Image
        {...otherProps}
        srcSet={srcSet || getUserAvatar() || url}
        onError={onError}
        className={clsx(classes.root, url ? "avatar" : "game", className)}
        width={avatarWidth}
        height={avatarHeight}
        radius={avatarRadius}
        url={getUserAvatar()}
        alt={alt}
        key={srcSet || getUserAvatar() || url}
      />
      {userRank?.id && getSizeBadge(size) && (
        <BadgeGame
          size={getSizeBadge(size)}
          type={
            isFinishedAnimation && nextRank?.id ? nextRank?.id : userRank.id
          }
          className={clsx(
            classes.rank,
            getSizeBadge(size),
            !isFinishedAnimation && isRankAnimation && classes.rankAnimation,
            hideRank && !isHideRankId && classes.hideRank,
          )}
          onClick={onClickBadge}
          id={showProgress && !isHideRankId ? "rank-badge" : ""}
          data-testid="rank-badge"
        />
      )}
      {crownIcon && (
        <StaticImage
          src={CrownIcon}
          src2x={CrownIcon2x}
          src3x={CrownIcon3x}
          className={classes.crownIcon}
        />
      )}
      {status &&
        (typeof status === "string" ? (
          <div className={clsx(classes.status)} />
        ) : (
          status
        ))}
      {showProgress &&
        getSizeProgress(size) &&
        userRank &&
        !isFinishedAnimation && (
          <ProgressBar
            color={userRank.color || RankColor.Unranked}
            value={{
              initial: userRank?.currentXp || 0,
              total: userRank?.nextLevelXpNeeded || 100,
              target: (userRank?.currentXp || 0) + (nbGem || 0),
            }}
            type="circle"
            size={getSizeProgress(size)}
            className={classes.progress}
            isDisableAnimation={!isRankAnimation}
          />
        )}
      {showProgress &&
        getSizeProgress(size) &&
        nextRank &&
        isFinishedAnimation && (
          <ProgressBar
            color={nextRank?.color || RankColor.Unranked}
            value={{
              initial: nextRank?.currentXp || 0,
              total: nextRank?.nextLevelXpNeeded || 100,
              target: nextRank?.currentXp || 0,
            }}
            type="circle"
            size={getSizeProgress(size)}
            className={classes.progress}
          />
        )}
    </div>
  );
};

Avatar.defaultProps = {
  size: "m",
};
