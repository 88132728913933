import { QueryOptions } from "@apollo/client";
import {
  meGQL,
  userTransactionsGQL,
  coinWalletsGQL,
} from "../../queries/users";
import apollo from "../../../clients/apollo";
import { Query } from "../../../graphql/graphql";
import { IParamsTransaction } from "../../../types/user";

const apolloClient = apollo.getInstance();

export const queryMe = async (
  options: Partial<QueryOptions> = {},
): Promise<Query["me"]> => {
  const res = await apolloClient.query({
    ...options,
    query: meGQL,
  });

  return res.data.me;
};

export const queryUserTransactions = async (
  params: IParamsTransaction,
  options: Partial<QueryOptions> = {},
): Promise<Query["user"]> => {
  const res = await apolloClient.query({
    ...options,
    query: userTransactionsGQL,
    variables: {
      id: params?.userId,
      coinTypes: params.coinTypes,
      limit: params.limit,
      offset: params.offset,
    },
  });

  return res.data.user;
};

export const queryCoinWallets = async (
  options: Partial<QueryOptions> = {},
): Promise<Query["me"]> => {
  const res = await apolloClient.query({
    ...options,
    query: coinWalletsGQL,
  });

  return res.data.me;
};
