import React, { FC, ImgHTMLAttributes } from "react";
import CoinWebp from "./assets/coin.webp";
import Coin2xWebp from "./assets/coin@2x.webp";
import Coin3xWebp from "./assets/coin@3x.webp";
import GemIcon from "./assets/gem_1x.webp";
import GemIcon2x from "./assets/gem_2x.webp";
import GemIcon3x from "./assets/gem_3x.webp";

interface IChipProps {
  type: "coin" | "gem";
  size?: number;
}

export const Chip: FC<ImgHTMLAttributes<HTMLImageElement> & IChipProps> = (
  props,
) => {
  const { type, size, ...otherProps } = props;
  const shipSrc =
    type === "coin"
      ? `${CoinWebp} 1x, ${Coin2xWebp} 2x, ${Coin3xWebp} 3x`
      : `${GemIcon} 1x, ${GemIcon2x} 2x, ${GemIcon3x} 3x`;

  return (
    <img
      {...otherProps}
      width={size}
      height={size}
      srcSet={shipSrc}
      alt={type}
    />
  );
};

Chip.defaultProps = {
  type: "coin",
};
