import { gql } from "graphql-tag";

export const getListInventoriesGQL = gql`
  query Inventories($input: InventoriesInput!) {
    inventories(input: $input) {
      items {
        applyImmediately
        category
        costs {
          coinType
          amount
        }
        createdAt
        data {
          ... on InventoryBundleData {
            __typename
            items
            previewUrl
          }
          ... on InventoryAvatarData {
            __typename
            url
          }
        }
        id
        isBundle
        isPurchased
        status
        title
        updatedAt
      }
      total
    }
  }
`;

export const getUserInventoriesGQL = gql`
  query Me($input: InventoriesInput) {
    me {
      id
      inventories(input: $input) {
        items {
          applyImmediately
          category
          createdAt
          data {
            ... on InventoryAvatarData {
              __typename
              url
            }
            ... on InventoryBundleData {
              __typename
              items
              previewUrl
            }
          }
          id
          isBundle
          isPurchased
          status
          title
          updatedAt
        }
        total
      }
    }
  }
`;
