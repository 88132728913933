interface IAwsConfig {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {
    domain: string;
    scope: string[];
    responseType: string;
    redirectSignIn: string;
    redirectSignOut: string;
  };
  federationTarget: string;
  aws_cloud_logic_custom: string[];
  Storage: {
    AWSS3: {
      bucket: string;
      region: string;
    };
  };
}

interface IOnmoConfig {
  pushNotification?: IOnmoConfigPushNotification;
  cms?: IOnmoConfigCms;
  analytics?: IOnmoConfigAnalytics;
  api?: IOnmoConfigApi;
  i18n?: IOnmoConfigI18n;
  helpShift?: IOnmoConfigHelpShift;
  auth?: IOnmoConfigAuth;
  debug?: IOnmoConfigDebug;
}

interface IOnmoConfigDebug {
  themes: { [key: string]: string };
  enabled?: boolean;
  versionPrefix?: string;
}

interface IOnmoConfigAuth {
  forceSessionAuth?: boolean;
}

interface IOnmoConfigHelpShift {
  platformId?: string;
  domain?: string;
  token?: string;
}

interface IOnmoConfigI18n {
  languages?: string[];
  rtlLanguages?: string[];
  default?: string;
}

interface IOnmoConfigApi {
  wsApolloUrl?: string;
  httpApolloUrl?: string;
  legacyHttpApolloUrl?: string;
  primaryLegacyHttpApolloUrl?: string;
  streamUrl?: string;
  streamingRegion?: string;
}

interface IOnmoConfigAnalytics {
  gtmIds?: string[];
  gtmEnv?: string;
  gtmId?: string;
}

interface IOnmoConfigPushNotification {
  key?: string;
}

interface IOnmoConfigCms {
  cdnPath?: string;
  localazyPath?: string;
}

export const ONMO_CONFIG: IOnmoConfig = {
  versionPrefix: "prod",
  ...JSON.parse(import.meta.env.REACT_APP_ONMO_CFG || "{}"),
};

export const AWS_CONFIG: IAwsConfig = JSON.parse(
  import.meta.env.REACT_APP_AWS || "{}",
);

export enum AppName {
  Onmo = "ONMO",
  Dialog = "DIALOG",
}

export const GAME_IMAGE_ASSETS_ENDPOINT_V2 = `${ONMO_CONFIG?.cms?.cdnPath}assets/games_v2/`;
export const MOMENT_IMAGE_ASSETS_ENDPOINT = `${ONMO_CONFIG?.cms?.cdnPath}assets/moments/`;
export const TUTORIAL_ONBOARDING_ASSETS_ENDPOINT = `${ONMO_CONFIG?.cms?.cdnPath}assets/tutorial_onboarding/`;
export const APP_URL = `${window.location.protocol}//${
  window.location.hostname
}${window.location.port ? `:${window.location.port}` : ""}`;

declare global {
  interface Window {
    Helpshift: (event: string, ...params: object[]) => void;
    helpshiftConfig: {
      userId: string;
      userName: string;
      userEmail: string;
      language: string;
    };
  }
}
