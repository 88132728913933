import Keycloak from "keycloak-js";
import keycloakConfig from "../keycloak-config";
import { AppDispatch } from "../store";
import { initKeycloakFailure, initKeycloakSuccess } from "../slices/keycloak";

export const keycloakInstance = new Keycloak(keycloakConfig);

export const initKeycloak = async (dispatch: AppDispatch) => {
  try {
    const authenticated = await keycloakInstance.init({
      onLoad: "login-required",
      redirectUri: window.location.href,
    });

    if (authenticated) {
      dispatch(initKeycloakSuccess());
    }
  } catch (error) {
    const errorMessage =
      typeof error === "string" ? error : (error as Error).message;

    console.log("Failed to initialize Keycloak", errorMessage);
    dispatch(initKeycloakFailure(errorMessage));
  }
};
