import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { IHistoryTransactionGroup } from "../types/historyTransaction";
import {
  IConfig,
  INewSubscriptionTransaction,
  IOnboardingMetadata,
} from "../types/user";
import {
  getConfig as getConfigAPI,
  getUserProfile as getUserProfileAPI,
  getUnseenRewardTransaction as getUnseenRewardTransactionAPI,
  getUserB2bSubscription as getUserB2bSubscriptionAPI,
  getLoginRewards,
} from "../legacyGraphql/resolvers/queries/users";
import { AppDispatch, AppThunk, AsyncAppThunk, RootState } from "../store";
import { startMaintenance } from "./maintenance";
import { OnmoStorage } from "../models/onmoStorage";
import {
  IActivityCategoryWithFilters,
  categoryWithFilters,
} from "../models/activities/activities";
import {
  TabActivities,
  BattleTypeCategoryWithFilter,
} from "../constants/activities";
import {
  Activity,
  ActivityCategory,
  BattleFinishActivity,
  FriendStatus,
  FullTransaction,
  LoginRewardProgress,
  Login_Reward_Status,
  PermissionGroup,
  UserProfile,
  UserSubscription,
} from "../legacyGraphql/graphql";
import { getCurrentRank } from "../models/userFactory/userFactory";
import {
  getActivities,
  getTotalNbActivities,
} from "../legacyGraphql/resolvers/queries/activities";
import {
  checkInUser as checkInUserAPI,
  claimLoginReward,
} from "../legacyGraphql/resolvers/mutation/users";
import { isErrorMaintenance } from "../models/users/users";
import { isNumber } from "lodash-es";
import { GAME_RESOLUTION } from "../constants/games";
import { setNotificationError } from "./alert";
import { queryCoinWallets, queryMe } from "../graphql/resolvers/queries/users";
import {
  User,
  UserMetadata,
  UserUpdateInput,
  CoinType,
  CoinWallet,
} from "../graphql/graphql";
import {
  updateUserMetadata,
  updateUser as updateUserMutation,
} from "../graphql/resolvers/mutations/users";

interface FollowInfo {
  nbFollowing: number;
  nbFollowers: number;
}

interface IUser {
  userMetadata: UserMetadata | null;
  me: User | null;
  userProfile: (UserProfile & FollowInfo) | null;
  myProfile: (UserProfile & FollowInfo) | null;
  userTransactions: IHistoryTransactionGroup | null;
  hasNewNotification: boolean;
  wallets: CoinWallet[] | null;
  newSubscriptionTransaction: INewSubscriptionTransaction | null;
  config: IConfig | null;
  loginRewards: LoginRewardProgress[] | null;
  friendNotification: boolean;
  nbFriendBattleActivities: number;
  permissionGroup: PermissionGroup[];
  subscription: UserSubscription | null;
  unseenRewardTransactions: FullTransaction[] | null;
  userActivities: {
    [key: string | BattleTypeCategoryWithFilter]: {
      activities: Activity[];
      isLoading: boolean;
      offset: number;
      loadedAll: boolean;
    };
  };
}

export const defaultUserActivities = {
  activities: [],
  isLoading: false,
  offset: 0,
  loadedAll: false,
};

const initialState = {
  userMetadata: null,
  me: null,
  userProfile: null,
  myProfile: null,
  userTransactions: null,
  hasNewNotification: false,
  wallets: null,
  config: null,
  newSubscriptionTransaction: null,
  loginRewards: null,
  newBattleStreak: undefined,
  friendNotification: OnmoStorage.notiOpenFriendsPage(),
  nbFriendBattleActivities: 0,
  subscription: null,
  unseenRewardTransactions: null,
  userActivities: {
    All: { ...defaultUserActivities },
    Battles: { ...defaultUserActivities },
    Challenges: { ...defaultUserActivities },
    Tournaments: { ...defaultUserActivities },
    Leaderboards: { ...defaultUserActivities },
    Friends: { ...defaultUserActivities },
  },
  permissionGroup: [],
} as IUser;

export const resetUserState = createAction("user/resetState");

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    updateWallets: (
      state,
      action: PayloadAction<{ wallets: CoinWallet[] }>,
    ) => {
      state.wallets = action.payload.wallets;
    },
    currentOnmoUser: (
      state,
      action: PayloadAction<{
        me: User;
        metadata?: UserMetadata | null;
      }>,
    ) => {
      state.me = action.payload.me;
      state.userMetadata = action.payload.metadata ?? state.userMetadata;
    },
    updateUserSubscription: (
      state,
      action: PayloadAction<{ subscription: UserSubscription }>,
    ) => {
      state.subscription = action.payload.subscription;
    },
    updateUserUnseenRewardTransactions: (
      state,
      action: PayloadAction<{
        unseenRewardTransactions: FullTransaction[];
      }>,
    ) => {
      state.unseenRewardTransactions = action.payload.unseenRewardTransactions;
    },
    updateUserProfile: (
      state,
      action: PayloadAction<{
        userProfile: (UserProfile & FollowInfo) | null;
      }>,
    ) => {
      state.userProfile = action.payload.userProfile || null;
    },
    updateLoginRewards: (
      state,
      action: PayloadAction<{
        loginRewards: LoginRewardProgress[] | null;
      }>,
    ) => {
      state.loginRewards = action.payload.loginRewards || null;
    },
    updatePermissionGroup: (
      state,
      action: PayloadAction<{
        permissionGroup: PermissionGroup[];
      }>,
    ) => {
      state.permissionGroup = action.payload.permissionGroup;
    },
    updateMyUserProfile: (
      state,
      action: PayloadAction<{
        myProfile: (UserProfile & FollowInfo) | null;
      }>,
    ) => {
      state.myProfile = action.payload.myProfile
        ? action.payload.myProfile
        : null;
    },
    resetUserProfile: (state) => {
      state.userProfile = null;
    },
    currentConfig: (
      state,
      action: PayloadAction<{
        config: IConfig;
      }>,
    ) => {
      state.config = action.payload.config;
    },
    updateUserTransactions: (
      state,
      action: PayloadAction<{
        userTransactions: IHistoryTransactionGroup;
      }>,
    ) => {
      state.userTransactions = action.payload.userTransactions;
    },
    updateUserHasNewNotification: (
      state,
      action: PayloadAction<{
        hasNewNotification: boolean;
      }>,
    ) => {
      state.hasNewNotification = action.payload.hasNewNotification;
    },
    updateNewSubscriptionTransaction: (
      state,
      action: PayloadAction<{
        newSubscriptionTransaction: INewSubscriptionTransaction | null;
      }>,
    ) => {
      state.newSubscriptionTransaction =
        action.payload.newSubscriptionTransaction;
    },
    updateUserMetadata: (
      state,
      action: PayloadAction<{
        userMetadata: UserMetadata;
      }>,
    ) => {
      state.userMetadata = action.payload.userMetadata || null;
    },
    updateNbFriendBattleActivities: (
      state,
      action: PayloadAction<{
        nbFriendBattleActivities?: number;
      }>,
    ) => {
      state.nbFriendBattleActivities =
        action.payload.nbFriendBattleActivities || 0;
    },
    updateFriendNotification: (
      state,
      action: PayloadAction<{ friendNotification: boolean }>,
    ) => {
      state.friendNotification = action.payload.friendNotification;
    },
    addFriendInvitedActivities: (
      state,
      action: PayloadAction<{ activity: Activity }>,
    ) => {
      state.nbFriendBattleActivities = state.nbFriendBattleActivities + 1;
      state.userActivities[TabActivities.Friends].activities = [
        action.payload.activity,
        ...state.userActivities[TabActivities.Friends].activities,
      ];
    },
    deleteFriendInvitedActivities: (
      state,
      action: PayloadAction<{
        matchId: string;
      }>,
    ) => {
      const newFriendActivities = state.userActivities[
        TabActivities.Friends
      ].activities?.filter(
        (item) =>
          (item as BattleFinishActivity).battleId !== action.payload.matchId,
      );
      state.userActivities[TabActivities.Friends].activities =
        newFriendActivities;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.pending, (state, action) => {
        const type = action.meta.arg.type;
        if (type) {
          state.userActivities[type].isLoading = true;
        }
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        const items = action.payload;
        const limit = action.meta.arg.limit || 20;
        const type = action.meta.arg.type;
        const offset = action.meta.arg.offset || 0;
        state.userActivities[type].isLoading = false;
        if (
          offset > 0 &&
          state.userActivities[type].offset > offset + items.length
        ) {
          state.userActivities[type].loadedAll = true;
          return;
        }
        if (items) {
          state.userActivities[type].activities =
            offset === 0
              ? items
              : [...state.userActivities[type].activities, ...items];
        }
        state.userActivities[type].offset = offset + items.length;
        state.userActivities[type].loadedAll = items.length < limit;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        const type = action.meta.arg.type;
        if (type) {
          state.userActivities[type].isLoading = false;
        }
        console.error(action.error);
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const userId = action.meta.arg;
        const getUserProfile = action.payload.getUserProfile;
        const nbFollowing = action.payload.following.totalCount;
        const nbFollowers = action.payload.followers.totalCount;

        if (state.me?.id === userId) {
          state.myProfile = { ...getUserProfile, nbFollowing, nbFollowers };
        } else {
          state.userProfile = { ...getUserProfile, nbFollowing, nbFollowers };
        }
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.myProfile = null;
        state.userProfile = null;
      })
      .addCase(resetUserState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateWallets,
  currentOnmoUser,
  updateUserSubscription,
  updateUserUnseenRewardTransactions,
  updateUserProfile,
  updateLoginRewards,
  updateMyUserProfile,
  currentConfig: currentConfigAction,
  updateUserTransactions,
  updateUserHasNewNotification,
  updateNewSubscriptionTransaction,
  updateUserMetadata: updateUserMetadataAction,
  addFriendInvitedActivities,
  deleteFriendInvitedActivities,
  updateNbFriendBattleActivities,
  updateFriendNotification,
  resetUserProfile,
  updatePermissionGroup,
} = userSlice.actions;

export const checkInUser = (): AsyncAppThunk => async () => {
  await checkInUserAPI();
};

export const fetchCoinWalletsUser = (): AsyncAppThunk => async (dispatch) => {
  const { coinWallets } = await queryCoinWallets();
  dispatch(updateWallets({ wallets: coinWallets }));
};

export const getOnmoUser =
  (isSubscription = false, options = {}): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      const me = await queryMe(options);
      isSubscription && dispatch(updateUserB2bSubscription());
      isSubscription && dispatch(getUnseenRewardTransaction());
      const state = getState();
      OnmoStorage.setUserRank(me.xp, state.user.config?.tierLevels);
      dispatch(
        currentOnmoUser({
          me,
          metadata: me.metadata,
        }),
      );
    } catch (e) {
      if (isErrorMaintenance(e)) {
        dispatch(startMaintenance({ reason: "" }));
      } else {
        // dispatch(logoutKeycloak());
        dispatch(startMaintenance({ reason: "servers" }));
      }
      console.error(e);
    }
  };
export const updateUserXp =
  (xp: number): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state.user.me) return;

    dispatch(
      currentOnmoUser({
        me: {
          ...state.user.me,
          xp: state.user.me.xp + xp,
        },
      }),
    );
  };

export const getUnseenRewardTransaction =
  (options = {}): AsyncAppThunk =>
  async (dispatch) => {
    const unseenRewardTransactions = await getUnseenRewardTransactionAPI(
      options,
    );
    dispatch(
      updateUserUnseenRewardTransactions({
        unseenRewardTransactions,
      }),
    );
  };

export const updateUserB2bSubscription =
  (): AsyncAppThunk => async (dispatch) => {
    const subscription = await getUserB2bSubscriptionAPI();
    dispatch(updateUserSubscription({ subscription }));
  };

export const getConfig =
  (options = {}): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const config = await getConfigAPI(options);
      OnmoStorage.setConFig(JSON.stringify(config));
      dispatch(currentConfigAction({ config }));
    } catch (e) {
      console.error(e);
    }
  };

export const updateHasSeenWelcomePopup =
  (): AsyncAppThunk => async (dispatch, getState) => {
    await updateUserMetadata({ hasSeenWelcomePopup: true });

    const state = getState();
    if (state.user.userMetadata) {
      dispatch(
        updateUserMetadataAction({
          userMetadata: {
            ...state.user.userMetadata,
            hasSeenWelcomePopup: true,
          },
        }),
      );
    }
  };

export const updateHasReceivedWelcomeCoins =
  (): AsyncAppThunk => async (dispatch, getState) => {
    await updateUserMetadata({ hasReceivedWelcomeCoins: true });

    const state = getState();
    if (state.user.userMetadata) {
      dispatch(
        updateUserMetadataAction({
          userMetadata: {
            ...state.user.userMetadata,
            hasReceivedWelcomeCoins: true,
          },
        }),
      );
    }
  };

export const updateOnboarding =
  (onboarding: IOnboardingMetadata): AsyncAppThunk =>
  async (dispatch, getState) => {
    let state = getState();
    const parseOnboarding = JSON?.parse(
      state.user.userMetadata?.onboarding || "{}",
    );

    const newOnboarding = JSON.stringify({
      ...parseOnboarding,
      ...onboarding,
    });

    await updateUserMetadata({
      onboarding: newOnboarding,
    });

    state = getState();
    if (state.user.userMetadata) {
      dispatch(
        updateUserMetadataAction({
          userMetadata: {
            ...state.user.userMetadata,
            onboarding: newOnboarding || "{}",
          },
        }),
      );
    }
  };

export const updateLastSeenTransactionId =
  (lastSeenTransactionId: string): AsyncAppThunk =>
  async (dispatch, getState) => {
    await updateUserMetadata({
      lastSeenTransactionId,
    });

    const state = getState();
    if (state.user.userMetadata) {
      dispatch(
        updateUserMetadataAction({
          userMetadata: {
            ...state.user.userMetadata,
            lastSeenTransactionId: lastSeenTransactionId,
          },
        }),
      );
    }
  };

export const updateDesiredStreamResolution =
  (desiredStreamResolution: number): AsyncAppThunk =>
  async (dispatch, getState) => {
    await updateUserMetadata({
      desiredStreamResolution,
    });
    const state = getState();
    if (state.user.userMetadata) {
      dispatch(
        updateUserMetadataAction({
          userMetadata: {
            ...state.user.userMetadata,
            desiredStreamResolution: desiredStreamResolution,
          },
        }),
      );
    }
  };

export const updateFriendStatus =
  (status: FriendStatus): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const userProfile = state.user.userProfile;
    if (userProfile) {
      dispatch(
        updateUserProfile({
          userProfile: {
            ...userProfile,
            friendStatus: status,
          },
        }),
      );
    }
  };

export const fetchLoginRewards = (): AsyncAppThunk => async (dispatch) => {
  const loginRewards = await getLoginRewards({
    fetchPolicy: "network-only",
  });

  if (loginRewards) {
    dispatch(
      updateLoginRewards({
        loginRewards,
      }),
    );
  }
};

export const claimLoginRewards =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const state = getState();
    const loginRewards = state.user.loginRewards;

    const claimedRewardIndex = async (index: number) => {
      try {
        await claimLoginReward({ rewardIndex: index });
      } catch (error) {
        console.error(error);
      }
    };

    const claimedRewards = loginRewards?.map((reward, index) => {
      if (reward.status === Login_Reward_Status.Unclaimed) {
        claimedRewardIndex(index);
        return { ...reward, status: Login_Reward_Status.Claimed };
      } else return reward;
    });

    dispatch(
      updateLoginRewards({
        loginRewards: claimedRewards || null,
      }),
    );
  };

export const fetchNbFriendActivities =
  (): AsyncAppThunk => async (dispatch) => {
    try {
      const activities = await getTotalNbActivities({
        category: ActivityCategory.Friends,
      });
      dispatch(
        updateNbFriendBattleActivities({
          nbFriendBattleActivities: activities.totalCount,
        }),
      );
      if (activities.totalCount > 0) {
        dispatch(
          updateFriendNotification({
            friendNotification: true,
          }),
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        updateNbFriendBattleActivities({
          nbFriendBattleActivities: 0,
        }),
      );
    }
  };

export const updateTimeOpenFriendsPage =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const state = getState();

    OnmoStorage.setTimeOpenFriendsPage();
    dispatch(
      updateFriendNotification({
        friendNotification: state.user.nbFriendBattleActivities > 0,
      }),
    );
  };

export const updateUser =
  (updateUserInput: Partial<UserUpdateInput>): AsyncAppThunk =>
  async (dispatch, getState) => {
    await updateUserMutation(updateUserInput);

    const state = getState();
    if (state.user.me) {
      dispatch(
        currentOnmoUser({
          me: {
            ...state.user.me,
            ...updateUserInput,
          },
        }),
      );
    }
  };

/*---------- Create Async Thunk ----------*/

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (userId: string, { dispatch, signal }) => {
    try {
      const userProfile = await getUserProfileAPI(userId, {
        fetchPolicy: "network-only",
        context: {
          fetchOptions: {
            signal,
          },
        },
      });
      return userProfile;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(
          setNotificationError(`Fail to fetch user profile` + e.message),
        );
      }
      throw e;
    }
  },
);

export const fetchActivities = createAsyncThunk(
  "user/fetchActivities",
  async (
    params: {
      type: BattleTypeCategoryWithFilter;
      limit?: number;
      offset?: number;
    },
    { dispatch, signal },
  ) => {
    const { type, limit = 20, offset = 0 } = params;

    const options: {
      limit?: number;
      offset?: number;
      category?: string;
      categoryWithFilters?: IActivityCategoryWithFilters;
    } = {
      limit,
      offset,
    };

    if (type === TabActivities.All) {
      options.category = ActivityCategory.Profile;
    } else if (type === TabActivities.Friends) {
      options.category = ActivityCategory.Friends;
    } else {
      options.categoryWithFilters = categoryWithFilters(type);
    }

    const activities = await getActivities(options, {
      fetchPolicy: "network-only",
      context: {
        fetchOptions: {
          signal,
        },
      },
    });

    dispatch(
      updateNbFriendBattleActivities({
        nbFriendBattleActivities: activities.totalCount,
      }),
    );

    return activities.items;
  },
);

/*---------- Create Selector ----------*/

export const currentRewardIndex = createSelector(
  [(state: RootState) => state.user.loginRewards],
  (loginRewards) => {
    const availableReward = loginRewards?.filter(
      (reward) => reward.status !== Login_Reward_Status.Unavailable,
    );

    return (availableReward?.length || 0) - 1;
  },
);

export const selectStreamResolution = createSelector(
  (state: RootState) => state,
  (state) => {
    const defaultStreamResolution = parseInt(
      state.theme.theme.pages.game?.defaultStreamResolution || "",
      10,
    );
    const isValidStreamResolution = GAME_RESOLUTION.includes(
      defaultStreamResolution,
    );
    const streamSelectedResolution =
      state.user.userMetadata?.desiredStreamResolution ||
      (isValidStreamResolution && defaultStreamResolution) ||
      GAME_RESOLUTION[0];
    return streamSelectedResolution;
  },
);

export const selectUserRank = createSelector(
  [
    (state: RootState) => state.user.me,
    (state: RootState) => state.user.config,
    (state: RootState, xp?: number) => xp,
  ],
  (me, config, xp) => {
    if (isNumber(xp)) {
      return getCurrentRank(xp, config?.tierLevels);
    } else {
      return getCurrentRank(me?.xp, config?.tierLevels);
    }
  },
);

export const selectOnboarding = createSelector(
  [(state: RootState) => state.user.userMetadata],
  (userMetadata) => {
    if (!userMetadata) return null;
    return JSON.parse(userMetadata?.onboarding || "{}");
  },
);

export const isInternalUserSelector = createSelector(
  [(state: RootState) => state.user.me],
  (me) => {
    const internalMailList = ["onmo.com", "onmobile.com", "nelisoftwares.com"];

    if (!me?.email) return false;
    return internalMailList.includes(me?.email.split("@")[1]);
  },
);

export const selectSpendableGems = createSelector(
  [(state: RootState) => state.user.wallets],
  (wallets) => {
    const coinType = wallets?.find(
      (w) => w.itemType === CoinType.SpendableGems,
    );
    return coinType?.balance ?? 0;
  },
);

export default userSlice.reducer;
