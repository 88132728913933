import { useEffect } from "react";
import { Routing } from "./routes";
import { ThemeProvider } from "react-jss";
import { withProfiler } from "@sentry/react";
import { useAppDispatch, useAppSelector } from "./hooks";
import { OnmoSplashScreen } from "./components/common/OnmoSplashScreen";
import { fetchTheme } from "./slices/theme";
import { UserState } from "./slices/keycloak";
import { OnmoLoading } from "./components/common/OnmoLoading";

function App() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);
  const splashScreen = useAppSelector((state) => state.splashScreen);
  const { authenticated, status } = useAppSelector((state) => state.keycloak);

  useEffect(() => {
    // HIDE LAZY LOADING
    const loadingElement = document.getElementsByClassName(
      "loader",
    )[0] as HTMLDivElement;
    if (loadingElement) {
      loadingElement.style.display = "none";
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTheme());
  }, [dispatch]);

  if (!authenticated && status !== UserState.SIGNIN) {
    return (
      <ThemeProvider theme={theme.theme}>
        <OnmoLoading />
      </ThemeProvider>
    );
  }

  const renderContent = () => {
    if (!authenticated) return;
    return (
      <>
        <OnmoSplashScreen
          open={splashScreen.isOpen}
          percentage={splashScreen.percentage}
        />
        <Routing />
      </>
    );
  };

  return <ThemeProvider theme={theme.theme}>{renderContent()}</ThemeProvider>;
}

export default withProfiler(App);
