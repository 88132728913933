import {
  PayloadAction,
  createAsyncThunk,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AppDispatch, AppThunk, RootState } from "../store";
import { GlobalLeaderboardsMd } from "../models/globalLeaderboards";
import {
  GlobalLeaderboard,
  GlobalLeaderboardItem,
  LeaderboardActivity,
  LeaderboardUserNotificationData,
} from "../legacyGraphql/graphql";
import { Time } from "../models/time";
import { setNotificationError } from "./alert";
import {
  LeaderboardType,
  LIST_TAB_LEADERBOARDS,
  TimeLine,
} from "../constants/leaderboards";
import { getGlobalLeaderboards } from "../legacyGraphql/resolvers/mutation/globalLeaderboards";

interface IGlobalLeaderboard {
  globalLeaderboards: GlobalLeaderboard | null;
  historyGlobalLeaderboard: GlobalLeaderboardItem | undefined;
  tournamentLeaderboard: GlobalLeaderboardItem | undefined;
  activeTab: string;
}

const initialState = {
  globalLeaderboards: null,
  historyGlobalLeaderboard: undefined,
  tournamentLeaderboard: undefined,
  activeTab: LIST_TAB_LEADERBOARDS[0],
} as IGlobalLeaderboard;

export const resetGlobalLeaderboardState = createAction(
  "globalLeaderboard/resetState",
);

export const globalLeaderboardSlice = createSlice({
  name: "globalLeaderboard",
  initialState: initialState,
  reducers: {
    updateGlobalLeaderboards: (
      state,
      action: PayloadAction<{ globalLeaderboards: GlobalLeaderboard | null }>,
    ) => {
      state.globalLeaderboards = action.payload.globalLeaderboards;
    },
    updateHistoryGlobalLeaderboard: (
      state,
      action: PayloadAction<{
        historyGlobalLeaderboard: GlobalLeaderboardItem | undefined;
      }>,
    ) => {
      state.historyGlobalLeaderboard = action.payload.historyGlobalLeaderboard;
    },
    updateTournamentLeaderboard: (
      state,
      action: PayloadAction<{
        tournamentLeaderboard: GlobalLeaderboardItem | undefined;
      }>,
    ) => {
      state.tournamentLeaderboard = action.payload.tournamentLeaderboard;
    },

    updateActiveTab: (state, action: PayloadAction<{ activeTab: string }>) => {
      state.activeTab = action.payload.activeTab;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalLeaderboardProfile.rejected, (state) => {
        state.historyGlobalLeaderboard = undefined;
      })
      .addCase(getGlobalLeaderboardProfile.fulfilled, (state, action) => {
        state.historyGlobalLeaderboard = action.payload;
      })
      .addCase(resetGlobalLeaderboardState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGlobalLeaderboards,
  updateActiveTab,
  updateHistoryGlobalLeaderboard,
  updateTournamentLeaderboard,
} = globalLeaderboardSlice.actions;

export const fetchGlobalLeaderboards = (): AppThunk => async (dispatch) => {
  try {
    const globalLeaderboards = await getGlobalLeaderboards();
    dispatch(updateGlobalLeaderboards({ globalLeaderboards }));
    return globalLeaderboards;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
    dispatch(updateGlobalLeaderboards({ globalLeaderboards: null }));
  }
};

export const getGlobalLeaderboardProfile = createAsyncThunk(
  "globalLeaderboard/getGlobalLeaderboardProfile",
  async (
    lbRequestData: {
      lbActivity?: LeaderboardActivity;
      lbNotificationData?: LeaderboardUserNotificationData;
    },
    { dispatch, signal },
  ) => {
    try {
      const { lbActivity, lbNotificationData } = lbRequestData;
      const globalLeaderboardInput = {
        leaderboardType: GlobalLeaderboardsMd.getLeaderboardType(
          lbActivity?.type ?? lbNotificationData?.leaderboardType,
        ),
        endDate: Time.convertTimestampToFormattedDate(
          lbActivity
            ? parseInt(lbActivity.endDate)
            : (lbNotificationData?.endDate as string),
        ),
      };

      const historyGlobalLeaderboard =
        await GlobalLeaderboardsMd.getGlobalLeaderboardProfile(
          globalLeaderboardInput.endDate,
          globalLeaderboardInput.leaderboardType || "",
          {
            fetchPolicy: "network-only",
            context: {
              fetchOptions: {
                signal,
              },
            },
          },
        );

      return historyGlobalLeaderboard;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
    }
  },
);

const selectGlobalLeaderboardData = (state: RootState) => ({
  globalLeaderboards: state.globalLeaderboard.globalLeaderboards,
  historyGlobalLeaderboard: state.globalLeaderboard.historyGlobalLeaderboard,
  tournamentLeaderboard: state.globalLeaderboard.tournamentLeaderboard,
  activeTab: state.globalLeaderboard.activeTab,
  me: state.user.me,
});

export const selectCurrentLeaderboard = createSelector(
  [
    selectGlobalLeaderboardData,
    (state: RootState, leaderboardType?: LeaderboardType) => leaderboardType,
  ],
  (selectGlobalLeaderboardData, leaderboardType) => {
    const {
      globalLeaderboards,
      historyGlobalLeaderboard,
      tournamentLeaderboard,
      activeTab,
      me,
    } = selectGlobalLeaderboardData;

    const currentLb = GlobalLeaderboardsMd.getCurrentLeaderboard(
      activeTab,
      globalLeaderboards,
      historyGlobalLeaderboard,
      tournamentLeaderboard,
      leaderboardType,
    );

    return {
      ...currentLb,
      leaderboard: GlobalLeaderboardsMd.getTop3Lb(
        currentLb?.leaderboard,
      )?.concat(
        GlobalLeaderboardsMd.getListOutTop3Lb(
          currentLb?.leaderboard,
          me,
          currentLb?.myRank || 0,
          currentLb?.myScore || 0,
        ) || [],
      ),
    };
  },
);

export const selectIsShowTimeLeft = createSelector(
  [
    selectGlobalLeaderboardData,
    (state: RootState, leaderboardType?: LeaderboardType) => leaderboardType,
  ],
  (selectGlobalLeaderboardData, leaderboardType) => {
    const { activeTab } = selectGlobalLeaderboardData;
    return (
      (leaderboardType === LeaderboardType.GlobalLeaderboard &&
        activeTab !== TimeLine.AllTime) ||
      leaderboardType === LeaderboardType.TournamentOnGoing
    );
  },
);

export default globalLeaderboardSlice.reducer;
